import React, { useState } from 'react';
import { isArrayLength } from '../../../util/genericHelpers';
import {
  ENUM_NAVBAR_ITEMS,
  FLUTES,
  NAVBAR_OPTIONS_INSTRUMENTS,
  WINEANDREED,
} from '../../../util/enums';
import { CATEGORIES } from '../../../config/configGeneric';
import classNames from 'classnames';

import css from './RenderChildrenCategories.module.css';

const Navbar = props => {
  const { onSelect } = props;
  const [subCategory, setSubCategory] = useState(null);
  const [subsubCategory, setSubSubCategory] = useState(null);

  const subcategories = CATEGORIES.find(category => category?.key === subCategory)?.subCategories;
  // const subsubCategories =
  //   isArrayLength(subcategories) &&
  //   subcategories.find(sc => sc?.key === subsubCategory)?.subSubCategories;

  return (
    <div className={css.megaDropdownMenu}>
      <div className={css.category}>
        {NAVBAR_OPTIONS_INSTRUMENTS.map((option, i) => (
          <>
            <div
              className={classNames(css.navbarCategories, {
                [css.activeSubcategory]: option?.key === subCategory,
              })}
              key={i}
              onClick={() => setSubCategory(subCategory ? null : option.key)}
            >
              {option?.label}
            </div>
            {option?.key === subCategory ? (
              <div className={css.subCategoriesWrapper}>
                <div className={css.subcategories}>
                  {isArrayLength(subcategories) &&
                    subcategories.map((sc, i) => (
                      <div
                        className={classNames(css.verticalNavbarLink, {
                          [css.activeSubcategory]: sc?.key === subsubCategory,
                        })}
                        onClick={() => {
                          setSubSubCategory(sc.key);
                          onSelect({ pub_category: ENUM_NAVBAR_ITEMS.INSTRUMENTS });
                          onSelect({ pub_subcategory: subCategory });
                          onSelect({ pub_subsubcategory: subsubCategory });
                        }}
                        key={i}
                      >
                        {sc?.label}
                      </div>
                    ))}
                </div>
              </div>
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
