import React, { useEffect, useState } from 'react';
import css from './MainPanelHeader.module.css';
import { Icons } from '../../../components';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { isArrayLength } from '../../../util/genericHelpers';

function RenderAppliedFilters(props) {
  const { history, initialFilters } = props;
  delete initialFilters.keywords;
  delete initialFilters.address;
  delete initialFilters.bounds;
  delete initialFilters.price;

  // Parse initial filters and remove 'has_all:' or 'has_any:'
  const parseFilters = filters => {
    const parsed = {};
    Object.keys(filters).forEach(key => {
      // Replace 'has_all:' and 'has_any:' prefixes
      let value =
        typeof filters[key] === 'string'
          ? filters[key].replace(/has_all:|has_any:/g, '')
          : filters[key];

      if (typeof value === 'string') {
        // Split other string values normally
        parsed[key] = value.split(',');
      } else {
        // Convert non-string, non-array values to an array
        parsed[key] = Array.isArray(value) ? value : [value];
      }
    });
    return parsed;
  };

  const [filters, setFilters] = useState(parseFilters(initialFilters));

  useEffect(() => {
    setFilters(parseFilters(initialFilters));
  }, [initialFilters]);

  const removeFilter = (key, value) => {
    const updated = { ...filters };
    updated[key] = updated[key].filter(item => item !== value);
    if (updated[key].length === 0) {
      delete updated[key];
    }
    setFilters(updated);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, updated));
  };

  const getLabel = value => {
    return value;
  };

  return (
    <div className={css.filtersApplied}>
      {Object.entries(filters).map(([key, values]) =>
        values.map(value => (
          <span key={`${key}-${value}`} className={css.filterPill}>
            {getLabel(value, key)}
            <span className={css.remove} onClick={() => removeFilter(key, value)}>
              &times;
            </span>
          </span>
        ))
      )}
    </div>
  );
}

export default RenderAppliedFilters;
