import React from 'react';
import Select from 'react-select';
import { countryCodes } from '../../../translations/countryCodes';

import css from './MultiSelectFilter.module.css';

function MultiSelectFilter(props) {
  const { config } = props;
  const label = config?.saveConfig?.label;
  const options = countryCodes.map(c => ({ value: c?.en, label: c?.en }));

  const customStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      cursor: 'pointer',
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
      fontWeight: '400',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#eee' : isSelected ? '#fff' : isFocused ? '#1e4c2f' : null,
        color: isDisabled ? '#484848' : isSelected ? '#000' : isFocused ? '#fff' : '#000',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontFamily: "'Inter', sans-serif",
        fontSize: '14px',
        fontWeight: '400',
      };
    },
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };
  return (
    <div className={css.selectMultiFilterPlain}>
      <label className={css.label}>{label}</label>
      <Select
        isMulti
        name="colors"
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
      />
    </div>
  );
}

export default MultiSelectFilter;
